function download(url, fileName) {
    const element = document.createElement('a');
    element.download = fileName;
    element.href = url;
    element.target = '_blank';
    element.click();

    URL.revokeObjectURL(element.href);
}

export default download;